import './App.css';

function App() {
  function navigateToLink(link) {
    window.open(link, '_blank');
  }
  
  return (
    <div className='grid-container ' >
      <div className='overlay'/>
      <div  class="container">
        <img className='buychart' src='buychart.png'></img>
        <div class="containerso">
        <img className='imgso' onClick={()=>navigateToLink("https://twitter.com/bookoffcheems")}  src='X.png'></img>
        <img className='imgso' onClick={() => navigateToLink("https://t.me/bookoffcheems_Poral")}  src='TELE.png'></img>
      </div>
      </div>
      <img src="YaDd.gif" className='ya' ></img>
      <div className='scre'>
      <img className='grid-item1 ' src='/DALL·E_2024_03_16_17_34_50_Illustrate_a_meme_in_a_scribbly,_hand 1.png'></img>

      </div>
      <div className='scre'>
        <img className='grid-item2' src='/Comp 2_1.gif'></img>
        <img className='grid-item3' src='/f1d2fe7bafb49df1e6a17cd43d1cc7e1.gif'></img>

      </div>
      <div className='scre'>
        <img className='grid-item4' src='/19Me.png'></img>
        <img className='grid-item5' src='/1aSH.gif'></img>
        <img className='grid-item6' src='/2A3G.gif'></img>
        <img className='grid-item7' src='/qs4ll1FSxKnNHeSmom-ezgif.com-crop (1).gif'></img>
      </div>
      <img className='grid-item8' src='/book.png'></img>

    </div>
  );
}

export default App;
